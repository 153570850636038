
///
/// Vue Router - Routes
///

/// DEPENDENCIES
import { store } from '@/lib/store'
import { auth } from '@/lib/auth'

/// Views
const Auth = () => import(/* webpackChunkName: "auth" */ '@/views/Auth')
// const Frame = () => import(/* webpackChunkName: "frame" */ '@/views/Frame')
const Main = () => import(/* webpackChunkName: "main" */ '@/views/Main')

/// Partials
const AuthLogin = () => import(/* webpackChunkName: "auth" */ '@/views/partials/AuthLogin')
const AuthReset = () => import(/* webpackChunkName: "auth" */ '@/views/partials/AuthReset')
const AuthRegister = () => import(/* webpackChunkName: "auth" */ '@/views/partials/AuthRegister')
const MyApplications = () => import(/* webpackChunkName: "main" */ '@/views/partials/MyApplications')
const Profile = () => import(/* webpackChunkName: "profile" */ '@/views/partials/Profile')
const Help = () => import(/* webpackChunkName: "help" */ '@/views/partials/Help')
const Applications = () => import(/* webpackChunkName: "applications" */ '@/views/partials/Applications')
const Invoices = () => import(/* webpackChunkName: "invoices" */ '@/views/partials/Invoices')
const Organizations = () => import(/* webpackChunkName: "organizations" */ '@/views/partials/Organizations')
const Registrations = () => import(/* webpackChunkName: "registrations" */ '@/views/partials/Registrations')
const Users = () => import(/* webpackChunkName: "users" */ '@/views/partials/Users')
const Privacy = () => import(/* webpackChunkName: "privacy" */ '@/views/partials/Privacy')
const Tools = () => import(/* webpackChunkName: "tools" */ '@/views/partials/Tools')
const Banners = () => import(/* webpackChunkName: "tools" */ '@/views/partials/Banners')

const ModalApplication = () => import(/* webpackChunkName: "applications" */ '@/components/ModalApplication')
const ModalOrganization = () => import(/* webpackChunkName: "organizations" */ '@/components/ModalOrganization')
const ModalRegistration = () => import(/* webpackChunkName: "registrations" */ '@/components/ModalRegistration')
const ModalUser = () => import(/* webpackChunkName: "users" */ '@/components/ModalUser')
const ModalSalesforce = () => import(/* webpackChunkName: "tools" */ '@/components/ModalSalesforce')
const ModalBanner = () => import(/* webpackChunkName: "tools" */ '@/components/ModalBanner')

/// Components
const AuthSidebar = () => import(/* webpackChunkName: "auth" */ '@/components/AuthSidebar')
const MainHeader = () => import(/* webpackChunkName: "main" */ '@/components/MainHeader')
const MainNav = () => import(/* webpackChunkName: "main" */ '@/components/MainNav')

/// Functions
const authGuard = (to, from, next) => {
	let user = auth.getUser()
	if (user) {
		next()
	} else {
		next({ name: 'login', query: { from: to.path }})
	}
}

const mainComponents = (content, modal) => ({
	header: MainHeader,
	nav: MainNav,
	content,
	modal,
})

const authComponents = (content, modal) => ({
	content,
	modal,
	sidebar: AuthSidebar,
})

/// EXPORT
export const routes = [
	{
		path: '/',
		component: Main,
		beforeEnter: authGuard,
		children: [
			{
				name: 'main',
				path: '',
				components: mainComponents(MyApplications),
			},
			{
				name: 'profile',
				path: 'profile',
				components: mainComponents(Profile),
			},
			{
				name: 'help',
				path: 'help',
				components: mainComponents(Help),
			},
			{
				name: 'applications',
				path: 'applications',
				components: mainComponents(Applications),
				children: [
					{
						name: 'application',
						path: '/application/:appId?',
						components: {
							pageModal: ModalApplication,
						},
						meta: {
							modalTitle: `Manage Resource`,
						},
					},
				],
			},
			{
				name: 'invoices',
				path: 'invoices',
				components: mainComponents(Invoices),
			},
			{
				name: 'organizations',
				path: 'organizations',
				components: mainComponents(Organizations),
				children: [
					{
						name: 'organization',
						path: '/organization/:orgId?',
						components: {
							pageModal: ModalOrganization,
						},
						meta: {
							modalTitle: `Manage Organization`,
						},
					},
				]
			},
			{
				name: 'registrations',
				path: 'registrations',
				components: mainComponents(Registrations),
				children: [
					{
						name: 'registration',
						path: '/registration/:regId?',
						components: {
							pageModal: ModalRegistration,
						},
						meta: {
							modalTitle: `Manage Registration`,
						},
					},
				]
			},
			{
				name: 'users',
				path: 'users',
				components: mainComponents(Users),
				children: [
					{
						name: 'user',
						path: '/user/:uid?',
						components: {
							pageModal: ModalUser,
						},
						meta: {
							modalTitle: `Manage User`,
						},
					},
				]
			},
			{
				name: 'tools',
				path: 'tools',
				components: mainComponents(Tools),
			},
			{
				name: 'banners',
				path: 'banners',
				components: mainComponents(Banners),
				meta: {
					modalTitle: `Banner Editor`,
				},
				children: [
					{
						name: 'banner',
						path: '/banner/:bannerId?',
						components: {
							pageModal: ModalBanner,
						},
						meta: {
							modalTitle: `Manage Banner`,
						},
					},
				],
			},
		],
	},
	{
		path: '/authenticate',
		component: Auth,
		children: [
			{
				name: 'login',
				path: '',
				components: authComponents(AuthLogin),
			},
			{
				name: 'reset',
				path: 'reset',
				components: authComponents(AuthReset),
			},
			{
				name: 'register',
				path: 'register',
				components: authComponents(AuthRegister),
			},
			{
				name: 'lostPassword',
				path: 'lost',
				components: authComponents(AuthLogin),
			},
		],
	},
	{
		path: '/privacy',
		component: Auth,
		children: [
			{
				name: 'privacy',
				path: '',
				components: authComponents(Privacy),
			},
		],
	},
	// {
	// 	path: '/frame',
	// 	component: Frame,
	// }
]
